<template>
  <div class="overlay">
    <div
      ref="overlayBg"
      class="overlay__bg"
      @click.prevent="closeOverlay"
    ></div>

    <div
      id="overlay__container"
      class="overlay__container"
      @click.prevent="closeOverlay"
    >
      <div class="overlay__wrapper" ref="overlayWrapper">
        <Notification />
      </div>
    </div>
  </div>
</template>

<script>
import Notification from "./Notification.vue";
import overlayHandling from "@/utilities/composition/overlay/overlayHandling";
import { gsap } from "gsap";

// ASYNC
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  components: { Notification },
  setup() {
    const store = useStore();

    const { closeOverlay } = overlayHandling();

    // Async component with options

    const generateId = computed(() => {
      return (
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9)
      );
    });

    const overlayTS = ref(null);
    const overlayComponent = ref(null);
    const overlayBg = ref(null);
    const overlayWrapper = ref(null);

    const randomId = ref(generateId);

    onMounted(() => {
      let OLW = overlayWrapper.value;
      let OLBG = overlayBg.value;

      gsap.set(OLBG, { opacity: 0, force3D: true });
      gsap.to(OLBG, { duration: 1, opacity: 1, delay: 1 });

      gsap.set(OLW, { y: "20vh", opacity: 0, rotation: 0.02, force3D: true });
      gsap.to(OLW, {
        duration: 0.5,
        y: 0,
        ease: "power2.out",
        opacity: 1,
        rotation: 0,
        delay: 1.4,
      });
    });

    return {
      overlayBg,
      overlayComponent,
      overlayWrapper,
      randomId,
      closeOverlay,
      overlayTS,
      store,
    };
  },
};
</script>

<style lang="scss">
.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  min-height: 420px;
}

.overlay__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 0;
}
.overlay__container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  position: absolute;
  z-index: 2;

  box-sizing: border-box;
  overflow: hidden;
  padding: 0 $main-padding;

  display: flex;
  justify-content: center;
  align-items: flex-start;
      overflow: auto;
}

.overlay__wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  
}

.overlay__content {
  position: relative;
}
.overlay__closeBg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
}

.overlay__component {
  position: relative;
  z-index: 10;
  width: 100%;
}

.overlay__chat {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 2000;
  flex-wrap: wrap;
  @media screen and (min-width: $medium) {
    position: fixed;
    z-index: 600;
    bottom: $lscontroller-height;
    right: 30px;
    left: auto;
    top: auto;
    z-index: 1000;
  }
}
</style>
