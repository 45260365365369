<template>
  <div class="notifications">
    <div class="introb1__wrapper introb1__wrapper--green">
      <div class="introb1__close">
        <AppButtonCloseOverlay />
      </div>

      <div class="introb1__content introb1__content">
        <div class="introb1__h1 h1 h1--white introb1__h1--nomarginbottom">


          WIR HABEN
          BETRIEBSURLAUB
          VOM <br> <span class="main_color">20.05.-26.05.</span> <br>
          Ab Montag den 27.
          Mai sind wir wieder wie gewohnt für euch da!
        </div>
        <div class="introb1__boxbutton">
          <AppButton class="introb1__btn white">Zurück zur Seite</AppButton>
        </div>
        <div class="introb1__logo">
          <TheLogo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppButtonCloseOverlay from "../../ui/buttons/AppButtonCloseOverlay.vue";
export default {
  components: { AppButtonCloseOverlay, TheLogo, AppButton },
};
</script>

<style lang="scss">
.notifications {
  background-color: $main-color-2;

  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.introb1__close {
  position: absolute;
  right: 5px;
  top: 10px;

  @media screen and (min-width: $medium) {
    right: 20px;
    top: 20px;
  }
}
</style>
