<template>
  <div class="helperbox">
    <div v-if="isControllOpen" class="overlay__controller">
      <OverlayController />
    </div>
    <div v-if="store.state.isNotificationOpen" class="overlay__notification">

    </div>
  </div>
</template>

<script>

import OverlayController from "@/components/common/overlay/OverlayController";


import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
export default {
  components: {
    OverlayController,
  },
  setup() {
    const store = useStore();
    const isControllOpen = computed(() => {
      return (
        store.state.isUserSettingsOpen ||
        store.state.isHelpDeskOpen ||
        store.state.isPeopleOpen
      );
    });

    return { store, isControllOpen };
  },
};
</script>

<style lang="scss">
.helperbox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
}
.overlay__chat {
  width: auto;
  height: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  @media screen and (min-width: $medium) {
    width: auto;
    height: auto;
    position: fixed;
    z-index: 600;
    bottom: $lscontroller-height;
    right: 30px;
    left: auto;
    top: auto;
    z-index: 1000;
  }
}

.overlay__controller {
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 4000;
  left: auto;
}
.overlay__livestreambar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $lscontroller-height;
  z-index: 1000;
}
</style>
