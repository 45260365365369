<template>
  <div class="logocontainer">
    <TheLogo class="logocontainer__logo" />
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import getPathFunction from "@/utilities/composition/getPathFunction";
import { reactive, toRefs } from "@vue/runtime-core";
export default {
  components: { TheLogo },
  setup() {
    const { getPath, setBackGroundPath } = getPathFunction();

    const state = reactive({
      link: "somelinklater",
    });

    return {
      getPath,
      setBackGroundPath,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.logocontainer {
  top: 0px;
  left: 0;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  @media screen and (min-width: $medium) {
  }
}
.logocontainer__logo {
  width: 33px;
  height: 46px;

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (min-width: $medium) {
    width: 40px;
    height: 57px;
  }
}
</style>
