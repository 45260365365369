<template>
  <div class="main">
    <div class="main__nav">
      <TheNav />
    </div>
    <main id="main__content">
      <router-view name="helper"></router-view>
    </main>
  </div>
  <TheOverlayMain v-if="getOverlayState"></TheOverlayMain>

  <HelperBox class="main__helperbox" />
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { useStore } from "vuex";
import TheNav from "@/components/common/navigation/TheNav";
import TheOverlayMain from "@/components/common/overlay/TheOverlayMain";
import HelperBox from "@/components/helpers/HelperBox";

import { nextTick, computed, watchEffect, onMounted } from "vue";

export default {
  name: "App",
  components: { TheNav, TheOverlayMain, HelperBox },
  setup() {
    const store = useStore();
    // lets define some computed const to watch things. so its more readble
    const getOverlayState = computed(() => store.state.isOverlayOpen);
    const getMenuState = computed(() => store.state.isMenuOpen);

    // ANIMATION
    //GSAP
    // let EASE = "slow(0.7, 0.7, false)";
    // let EASE = "elastic.out(1, 0.3)";
    let time = 0.5;
    function beforeEnter(el) {
      gsap.set(el, {
        opacity: 0,
      });
    }
    function enter(el, done) {
      gsap.to(el, {
        duration: time,
        opacity: 1,
        onComplete: done,
      });
    }
    function leave(el, done) {
      gsap.to(el, {
        duration: time,
        opacity: 0,
        onComplete: done,
      });
    }

    nextTick(() => {});

    watchEffect(() => {
      if (store.state.isOverlayOpen) {
        // document.body.style.overflow = "hidden";
      } else {
        // document.body.style.overflow = "auto";
      }
    });

    onMounted(() => {
      //POLITECHECK
      const appDom = document.querySelector("#app");
      window.addEventListener("load", function() {
        console.log("LOADED");
        ScrollTrigger.update();
        ScrollTrigger.refresh();
        ScrollTrigger.update();
        setTimeout(() => {
          appDom.style.visibility = "visible";

          store.commit("setPoliteLoad", true);
        }, 100);
      });
    });
    return {
      store,
      getOverlayState,
      getMenuState,
      beforeEnter,
      enter,
      leave,
    };
  },
};
</script>

<style lang="scss">
#app {
  position: relative;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.main {
  width: 100%;
  height: 100%;
  display: block;
}

.main__nav {
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  height: $navi-height-mobile;
  @media screen and (min-width: $medium) {
    height: $navi-height-desktop;
  }
}
.main__box {
  position: relative;
  z-index: 0;
}

.main__mainbar {
  height: 100%;
}

.main__helperbox {
  z-index: 200;
}

main {
  min-height: unset;
}
#main__content {
  // padding-top: $navi-height-mobile;
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (min-width: $medium) {
    // padding-top: $navi-height-desktop;
  }
}
</style>
