<template>
  <div class="navdesktop">
    <div class="navdektop__wrapper content-section">
      <div class="navdektop__leftblock">
        <div class="navdektop__btn">
          <router-link :to="prefixPath('ueber')" class="navdektop__routes">
            <NavBtn :dataname="'ueber'">Über Uns</NavBtn>
          </router-link>
        </div>
        <div class="navdektop__btn">
          <router-link :to="prefixPath('menu')" class="navdektop__routes">
            <NavBtn :dataname="'menu'">Menü</NavBtn>
          </router-link>
        </div>
      </div>
      <div class="navdektop__middleblock">
        <router-link :to="prefixPath('')" class="navdektop__routes">
          <NavLogoContainer />
        </router-link>
      </div>

      <div class="navdektop__rightblock">
        <div class="navdektop__btn" ref="people">
          <router-link
            :to="prefixPath('reservieren')"
            class="navdektop__routes"
          >
            <NavBtn :dataname="'reservieren'">Reservieren</NavBtn>
          </router-link>
        </div>
        <div class="navdektop__btn" ref="help">
          <router-link :to="prefixPath('kontakt')" class="navdektop__routes">
            <NavBtn :dataname="'kontakt'">Kontakt</NavBtn>
          </router-link>
        </div>
        <!-- <div class="navdektop__btn" ref="menu">
          <NavBtnUserSettings />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import NavBtn from "./NavBtn";
import NavLogoContainer from "./NavLogoContainer";
import { computed } from "@vue/runtime-core";

import { useStore } from "vuex";
export default {
  components: {
    NavBtn,
    NavLogoContainer,
  },

  setup() {
    const store = useStore();
    const publicPath = process.env.BASE_URL;

    const isPeople = computed(() => {
      return store.state.isPeopleOpen;
    });
    const isHelp = computed(() => {
      return store.state.isHelpDeskOpen;
    });
    const isMenuOpen = computed(() => {
      return store.state.isMenuOpen;
    });

    function prefixPath(el) {
      return publicPath + el;
    }

    function toggleElement(e, el) {
      console.log("e", e, el);
    }
    return {
      isPeople,
      isHelp,
      isMenuOpen,
      toggleElement,
      prefixPath,
    };
  },
};
</script>

<style lang="scss">
.navdesktop {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $main-color-1;
  color: $main-color-4;
}
.navdektop__wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  margin: 0 auto;
}

.navdektop__leftblock {
  display: flex;
  flex: 1 1 300px;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: nowrap;
}
.navdektop__middleblock {
  z-index: 10;
  flex: 1 1 auto;
  justify-content: flex-start;
  align-items: stretch;
}
.navdektop__rightblock {
  display: flex;
  flex: 1 1 300px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}

.navdektop__searchbox {
  height: 100%;
}
.navdektop__btn {
  height: 100%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  appearance: none;
  & * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    appearance: none;
  }
}

.navdektop__routes {
  display: block;
  height: 100%;
}
</style>
