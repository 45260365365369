<template>
  <div class="navmobile" :class="{ navopen: isNaveOpen }">
    <div ref="navmobileWrapper" class="navmobile__wrapper">
      <div class="navmobile__leftblock" @click.prevent="closeMenu">
        <router-link :to="prefixPath('')" class="no-touch-out">
          <NavLogoContainer />
        </router-link>
      </div>
      <div class="navmobile__rightblock">
        <NavMenuBtn />
      </div>
    </div>

    <div class="navmobile__buttonwrapper" ref="navbwrapper">
      <div class="navmobile__topblock">
        <div class="navmobile__btn" @click.prevent="closeMenu">
          <router-link :to="prefixPath('')" class="navmobile__routes">
            <NavBtn :dataname="''">Startseite</NavBtn>
          </router-link>
        </div>
        <div class="navmobile__btn" @click.prevent="closeMenu">
          <router-link :to="prefixPath('ueber')" class="navmobile__routes">
            <NavBtn :dataname="'ueber'">Über Uns</NavBtn>
          </router-link>
        </div>
        <div class="navmobile__btn" @click.prevent="closeMenu">
          <router-link :to="prefixPath('menu')" class="navmobile__routes">
            <NavBtn :dataname="'menu'">Menü</NavBtn>
          </router-link>
        </div>
        <div class="navmobile__btn" @click.prevent="closeMenu">
          <router-link
            :to="prefixPath('reservieren')"
            class="navmobile__routes"
          >
            <NavBtn :dataname="'reservieren'">Reservieren</NavBtn>
          </router-link>
        </div>
        <div class="navmobile__btn" @click.prevent="closeMenu">
          <router-link :to="prefixPath('kontakt')" class="navmobile__routes">
            <NavBtn :dataname="'kontakt'">Kontakt</NavBtn>
          </router-link>
        </div>
      </div>
      <div class="navmobile__bottomblock">
        <NavKontaktBtns class="navmobile__kontaktbtns" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBtn from "./NavBtn";
import NavMenuBtn from "./NavMenuBtn";
import NavKontaktBtns from "./NavKontaktBtns";
import NavLogoContainer from "./NavLogoContainer";
import { computed, onMounted, ref, watchEffect } from "@vue/runtime-core";

import gsap from "gsap";

import { useStore } from "vuex";
export default {
  components: {
    NavBtn,
    NavLogoContainer,
    NavMenuBtn,
    NavKontaktBtns,
  },

  setup() {
    const store = useStore();
    const publicPath = process.env.BASE_URL;

    const isNaveOpen = ref(false);

    const navmobileWrapper = ref(null);

    const isPeople = computed(() => {
      return store.state.isPeopleOpen;
    });
    const isHelp = computed(() => {
      return store.state.isHelpDeskOpen;
    });
    const isMenuOpen = computed(() => {
      return store.state.isMenuOpen;
    });

    const navbwrapper = ref(null);

    function prefixPath(el) {
      return publicPath + el;
    }

    function closeMenu() {
      store.commit("setMenuState", false);
    }

    function toggleElement(e, el) {
      console.log("e", e, el);
    }

    function killTwenns() {
      gsap.killTweensOf(navbwrapper.vaule);
    }
    function openAnimation() {
      isNaveOpen.value = true;
      killTwenns();

      gsap.to(navbwrapper.value, {
        duration: 0.5,
        y: 0,
        rotation: 0,
        ease: "power2.inOut",
      });
    }
    function closeAnimation() {
      killTwenns();
      gsap.to(navbwrapper.value, {
        duration: 0.5,
        y: "-100%",
        rotation: 0.02,
        ease: "power2.inOut",
        onComplete: () => {
          isNaveOpen.value = false;
        },
      });
    }

    onMounted(() => {
      gsap.set(navbwrapper.value, { height: window.innerHeight });

      window.addEventListener("resize", function() {
        gsap.set(navbwrapper.value, { height: window.innerHeight });
      });
    });

    watchEffect(() => {
      if (store.state.isMenuOpen) {
        document.body.style.overflow = "hidden";
        openAnimation();
      } else {
        document.body.style.overflow = "auto";
        closeAnimation();
      }
    });
    return {
      store,
      isPeople,
      isHelp,
      isMenuOpen,
      isNaveOpen,
      navbwrapper,
      closeMenu,
      toggleElement,
      prefixPath,
      navmobileWrapper,
    };
  },
};
</script>

<style lang="scss">
.navmobile {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $main-color-1;
  color: $main-color-4;
  // overflow: hidden;
}
.navmobile__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $main-color-1;

  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  margin: 0 auto;
  z-index: 100;
  padding: 0 36px;
}

.navmobile__leftblock {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
.navmobile__middleblock {
  z-index: 10;
  flex: 1 1 auto;
  justify-content: flex-start;
  align-items: stretch;
}
.navmobile__rightblock {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}

.navmobile__searchbox {
  height: 100%;
}
.navmobile__btn {
  height: 100%;
  width: 100%;
  flex: 0 1 50px;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  appearance: none;
  & * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    appearance: none;
  }
}

.navmobile__routes {
  display: block;
  height: 100%;
  width: 100%;
  font-size: 27px;
  line-height: 36px;
  letter-spacing: 0.16px;
}

.navmobile__buttonwrapper {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: $main-color-3;
  background-color: $main-color-1;
  display: flex;
  flex-direction: column;
  padding-top: $navi-height-mobile;
  justify-content: space-evenly;
  align-items: center;

  transform: translate(0, -100%);
}

.navmobile__topblock {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 1;
}

.navmobile__kontaktbtns {
  align-self: flex-start;
}

.navmobile__bottomblock {
  width: 100%;
  padding: 20px 36px;
  flex-grow: 0;
}
</style>
