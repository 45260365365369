<template>
  <div class="navkontaktbtns">
    <div class="navkontaktbtns__item">
      <a :href="getPhone" class="navkontaktbtns__a">
        <img
          :src="getPath('assets/img/svg/THI-icon_phone.svg')"
          alt=""
          class="navkontaktbtns__img"
        />
      </a>
    </div>
    <div class="navkontaktbtns__item">
      <a :href="getMail" class="navkontaktbtns__a">
        <img
          :src="getPath('assets/img/svg/THI-icon_mail.svg')"
          alt=""
          class="navkontaktbtns__img"
      /></a>
    </div>
    <div class="navkontaktbtns__item">
      <a :href="kontakt.googlemaps" target="_blank" class="navkontaktbtns__a">
        <img
          :src="getPath('assets/img/svg/THI-icon_map.svg')"
          alt=""
          class="navkontaktbtns__img"
        />
      </a>
    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import contactInformation from "@/utilities/siteinformation/contactInformation";
export default {
  setup() {
    const kontakt = contactInformation;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;

    const { getPath } = getPathFunction();

    return { kontakt, getMail, getPhone, getPath };
  },
};
</script>

<style lang="scss">
.navkontaktbtns {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.navkontaktbtns__item {
  text-align: center;
  height: 30px;
  color: $main-color-1;
  //   background-color: $main-color-1;
  text-transform: lowercase;
  opacity: 1;

  @media screen and (min-width: $medium) {
  }
}

.navkontaktbtns__img {
  height: 100%;
  width: auto;
}
</style>
